import React from 'react';
import './SafetyStandards.css'; // Importing the CSS file

const SafetyStandards = () => {
  return (
    <div className="safety-standards">
      <header>
        <h1>Child Sexual Abuse and Exploitation (CSAE) Safety Standards</h1>
      </header>

      <section className="intro">
        <h2>Introduction</h2>
        <p>
          At <strong>BhaktiVerse</strong>, we are committed to providing a safe, secure, and supportive environment for all
          our users, regardless of age. The protection of children from sexual abuse and exploitation is a top priority for
          us. We adhere to the highest standards of safety and comply with relevant child protection laws, including
          <strong>COPPA</strong>, <strong>GDPR</strong>, and other local regulations.
        </p>
      </section>

      <section className="policies">
        <h2>Child Protection Policies</h2>
        <ul>
          <li><strong>Universal Access for All Ages:</strong> BhaktiVerse is designed to be used by people of all ages with safety measures in place for minors.</li>
          <li><strong>Content Moderation:</strong> We use automated and manual content moderation to detect and remove harmful content.</li>
          <li><strong>Inappropriate Content Reporting:</strong> We encourage users to report harmful content through our reporting tool.</li>
        </ul>
      </section>

      <section className="privacy">
        <h2>Data Collection & Privacy</h2>
        <ul>
          <li><strong>Privacy Protection:</strong> We ensure privacy and safety of all users, especially minors.</li>
          <li><strong>No Harmful Data Collection:</strong> We do not collect or store personal data that could be misused.</li>
          <li><strong>Parental Controls:</strong> Parents can monitor and control their child’s experience within the app.</li>
        </ul>
      </section>

      <section className="report">
        <h2>Report CSAE Incidents</h2>
        <p>
          If you need to report an incident or share your concerns, please visit our <a href="/contact">Contact Us</a> page. We are here to listen and take prompt action.
        </p>
      </section>

      <section className="commitment">
        <h2>Commitment to Continuous Improvement</h2>
        <p>We are committed to continuously improving our safety policies and actively seek feedback from users. We regularly review and update our safety measures.</p>
      </section>

      <footer>
        <p>For more information, visit our <a href="https://www.bhaktiverse.com/safety-standards/csae">CSAE Safety Standards page</a>.</p>
      </footer>
    </div>
  );
};

export default SafetyStandards;