import React from 'react';
import './Download.css'; // Make sure this file contains your styles

function Download() {
  return (
    <div className="download">
      <h2>Download BhaktiVerse App</h2>
      <ul>
        <li>
          <a 
            href="https://play.google.com/store/apps/details?id=com.anitesh.bhaktiverse" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            Google Play Store
          </a>
        </li>
        <li>
          <span className="coming-soon">Apple App Store - Coming Soon</span>
        </li>
      </ul>
    </div>
  );
}

export default Download;