// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Welcome from './components/Welcome';
import Features from './components/Features';
import Download from './components/Download';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy';
import DevotionalContentPage from './components/DevotionalContentPage';
import PujaSamagriPage from './components/PujaSamagriPage';
import ContactPage from './components/ContactPage';
import AdminLoginPage from './components/AdminLoginPage';
import AdminFeedbackPage from './components/AdminFeedbackPage';
import SafetyStandards from './components/SafetyStandards';
//import BhagavadGitaPage from './components/BhagavadGitaPage';



function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="/features" element={<Features />} />
        <Route path="/download" element={<Download />} />
        <Route path="/devotional-content" element={<DevotionalContentPage />} />
        <Route path="/puja-samagri" element={<PujaSamagriPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/admin/login" element={<AdminLoginPage />} />
        <Route path="/admin/feedback" element={<AdminFeedbackPage />} />
        <Route path="/safety-standards" element={<SafetyStandards />} />
        {/* <Route path="/bhagavad-gita" element={<BhagavadGitaPage />} /> */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;